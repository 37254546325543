exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-compare-form-versions-js": () => import("./../../../src/pages/compareFormVersions.js" /* webpackChunkName: "component---src-pages-compare-form-versions-js" */),
  "component---src-pages-confirm-code-js": () => import("./../../../src/pages/confirmCode.js" /* webpackChunkName: "component---src-pages-confirm-code-js" */),
  "component---src-pages-edit-form-js": () => import("./../../../src/pages/editForm.js" /* webpackChunkName: "component---src-pages-edit-form-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgotPassword.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-password-reset-js": () => import("./../../../src/pages/passwordReset.js" /* webpackChunkName: "component---src-pages-password-reset-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-select-form-js": () => import("./../../../src/pages/selectForm.js" /* webpackChunkName: "component---src-pages-select-form-js" */),
  "component---src-pages-submit-form-js": () => import("./../../../src/pages/submitForm.js" /* webpackChunkName: "component---src-pages-submit-form-js" */),
  "component---src-pages-tables-js": () => import("./../../../src/pages/tables.js" /* webpackChunkName: "component---src-pages-tables-js" */)
}

